$primary: #32cd32;
// $info: #8247e5;
$link: $primary;
$hr-height: 1px;
@import "../node_modules/bulma/bulma";
$sidebar-bg-color: $white !important;
$icon-bg-color: $white !important;
$highlight-color: $black !important;
$submenu-bg-color-collapsed: $light !important;
$submenu-bg-color: $light !important;
$sidebar-width: 230px !important;
$navbar-dropdown-border-top: 1px solid #ddd !important;
@import "../node_modules/react-datepicker/dist/react-datepicker.css";

@font-face {
  font-family: "VCR";
  src: local("VCR"),
    url("./fonts/VCR/VCR_OSD_MONO_1.001 2.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

/* html */
html {
  @extend .has-navbar-fixed-top;
  background-color: $light;
  overflow-y: overlay;
}

.title {
  font-family: VCR, Arial, serif;
  @extend .is-uppercase;
}

.is-accent {
  font-family: VCR, Arial, serif;
  font-weight: 100!important;
  letter-spacing: 0px !important;
}

/**
Align element in center
 */
.is-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
Pro sidebar bug
 */
.pro-menu-item {
  &.active {
    .pro-icon {
      color: $primary !important;
    }
  }
  .pro-item-content a {
    color: $dark !important;
    &:hover {
      color: $black !important;
    }
  }
}

/**
For fixed top height
 */
.sidebar {
  top: 52px !important;
  .sidebar-sidebar {
    box-shadow: none !important;
  }
  .sidebar-content {
    transition: none !important;
  }
}

.modal {
  .modal-background {
    z-index: 9;
  }
  .modal-content {
    z-index: 10;
  }
  .modal-close {
    z-index: 11;
  }
  @include desktop {
    &.is-large {
      .modal-content {
        width: 80%;
      }
    }
  }
}

.has-modal-page {
  .box {
    padding: 0;
  }
}

.navbar-item {
  img {
    max-height: none;
  }
}

.content p {
  word-break: break-all;
}

.pagination-container {
  .pagination-previous,
  .pagination-next {
    display: none;
  }
}

.is-128x128-desktop {
  @extend .is-128x128;
  @include mobile {
    width: 64px !important;
    height: 64px !important;
  }
}

#Rune {
  .rune {
    @include mobile {
      width: 20% !important;
      height: 20% !important;
    }
  }
}

table {
  tr.is-flex {
    align-items: center;
    justify-content: space-between;
    td {
      border: none;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $light;
    }
  }
  th {
    font-size: $size-7;
    font-weight: $weight-semibold;
  }
  td {
    font-size: $size-7;
    vertical-align: middle !important;
  }
}

.has-scroll-hide::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

// .path {
//   stroke-dasharray: 1000;
//   stroke-dashoffset: 0;
//   &.circle {
//     -webkit-animation: dash 0.9s ease-in-out;
//     animation: dash 0.9s ease-in-out;
//   }
//   &.line {
//     stroke-dashoffset: 1000;
//     -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
//     animation: dash 0.9s 0.35s ease-in-out forwards;
//   }
//   &.check {
//     stroke-dashoffset: -100;
//     -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
//     animation: dash-check 0.9s 0.35s ease-in-out forwards;
//   }
// }
